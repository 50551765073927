// import i18n from '@/i18n/index'

export default {
  tableDataItem: [{
    prop: 'id',
    label: '',
    Object: 'value',
    width: '150'
  }, {
    prop: 'img',
    label: '',
    height: '100px',
    width: '160',
    img: true
  },{
    prop: 'name',
    label: '',
    Object: 'value',
    width: '150'
  },{
    prop: 'max_customer',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'price',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'rank',
    Object: 'value',
    label: '',
    width: '150'
  }, {
    prop: 'updated_at',
    label: '',
    Object: 'value',
    width: '100',
  }]
}

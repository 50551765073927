import {
  getRestTypeList,
  getRestSetup,
  deleteRestType
} from '@/services/rests'
import jsonData from './jsonData.js'
import allButton from '@/components/allButton'
import searchForm from '@/components/searchForm'
import createTable from '@/components/createTable'

import { mixin } from '@/mixins/mixin'
// import loading from '@/utils/loading.json'
export default {
  name: 'citiesList',
  mixins: [mixin],
  components: {
    searchForm,
    createTable,
    allButton
  },
  data () {
    return {
      tableDataItem: jsonData.tableDataItem
    }
  },
  watch: {
  },
  created () {
    this.queryData(() => getRestSetup('rest_types'))
  },
  activated () {
    if (this.isActive) this.queryData(() => getRestSetup('rest_types'))
    this.isActive = true
  },
  methods: {
    // 点击操作按钮
    async handleClick (type, data) {
      if (type === 'create') {
        this.$router.push({
          name: 'restsManagerCategoryUpdate'
        })
      }
      if (type?.action === 'view') {
        this.$router.push({
          name: 'restsManagerCategoryDetail',
          params: {
            id: data.id.value
          }
        })
      }
      if (type?.action === 'create') {
        this.$router.push({
          name: 'restsManagerCategoryUpdate'
        })
      }
      if (type?.action === 'edit') {
        this.$router.push({
          name: 'restsManagerCategoryUpdate',
          query: {
            id: data.id.value
          }
        })
      }
      if (type?.action === 'destroy') {
        this.deleteClick(data, deleteRestType)
      }
    },
    handleSwitchChange (data) {
      // const { jsonItemData, rowData } = data
      // this.$store.state.app.loading = this.$loading(loading)
      // if (rowData[jsonItemData.prop].value) {
      //   updateOnlineCouponOnline(rowData.id.value).then(res => {
      //     this.$message({
      //       type: 'success',
      //       message: this.$t('message.operateSuccess')
      //     })
      //   }).finally(() => {
      //     this.$store.state.app.loading.close()
      //   })
      // } else {
      //   updateOnlineCouponOffline(rowData.id.value).then(res => {
      //     this.$message({
      //       type: 'success',
      //       message: this.$t('message.operateSuccess')
      //     })
      //   }).finally(() => {
      //     this.$store.state.app.loading.close()
      //   })
      // }
    },
    // 查询
    search (data) {
      if (data) this.searchData = data
      const { page, per_page } = this.pageData
      Object.assign(this.searchData, { page, per_page })
      this.loading = true
      getRestTypeList(this.searchData).then(res => {
        if (res.data) {
          res.data.objects.map((d) => {
            if (d.images && d.images.length > 0) {
              d.img = d.images[0]?.image
            }
            return d
          })
          this.tableData = res.data.objects
          this.tabData = res.data.scopes
          Object.assign(this.pageData, res.data.pagination)
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
